









































































































































import { Component, Vue } from "vue-property-decorator";
import { search } from "@/services/AzureSearch";

import { mapMutations } from "vuex";

import states from "@/assets/dictionaries/state-list";
declare module "@/assets/dictionaries/state-list";

interface FormFields {
    name: string;
    keywords: Array<string>;
    products: string;
    lookingToHire: boolean;
    lookingForPartners: boolean;
    state: string;
}
@Component({
    computed: {
        ...mapMutations(["exhibitorSearchResults"])
    }
})
export default class ExhibitorSearchForm extends Vue {
    states = states;
    keywordEntry = "";

    formFields: FormFields = {
        name: "",
        keywords: [],
        products: "",
        lookingToHire: false,
        lookingForPartners: false,
        state: ""
    };

    // methods

    submitForm() {
        search(
            "cosmosdb-index-exhibitors",
            {
                name: this.formFields.name,
                products: this.formFields.products,
                keywords: this.keywordEntry
            },
            {
                state: this.formFields.state,
                lookingToHire: this.formFields.lookingToHire,
                lookingForPartners: this.formFields.lookingForPartners
            }
        ).then((results) => {
            this.$store.commit("exhibitorSearchResults", results.data["value"]);
            this.$router.push("tradeshow/results");
        });
    }

    clearForm() {
        this.keywordEntry = "";
        this.formFields = {
            name: "",
            keywords: [],
            products: "",
            lookingToHire: false,
            lookingForPartners: false,
            state: ""
        };
    }
}
