<template>
    <div>
        <div v-if="getLayoutOptions.usesAlternateTradeshowLayout">
            <section
                class="pt-2"
                :class="
                    cssVariables['--tradeshow-header-bg-color']
                        ? 'bg-tradeshow-header'
                        : 'bg-white'
                "
            >
                <div class="container p-6 md:p-12">
                    <!-- <h1
                        class="title mg-title-header text-center has-dark-text mg-font-bold"
                    >
                        Solutions Center
                    </h1> -->
                    <div
                        class="text-primary block m-auto text-center text-lg w-3/4"
                    >
                        <h1
                            class="title mg-title-header text-center has-dark-text mg-font-bold"
                        >
                            Thank you to our sponsors.
                        </h1>
                        <p>
                            For additional resources and information on each
                            company, please click the appropriate logo below.
                        </p>
                    </div>
                </div>
            </section>

            <SponsorsByTiers />
        </div>
        <div v-else>
            <section
                class="pt-2"
                :class="
                    cssVariables['--tradeshow-header-bg-color']
                        ? 'bg-tradeshow-header'
                        : 'bg-white'
                "
            >
                <div class="container p-6 md:p-12">
                    <h1
                        class="title mg-title-header text-center has-dark-text mg-font-bold"
                        :class="
                            getLayoutOptions.siteName == 'smacna'
                                ? 'text-white'
                                : ''
                        "
                    >
                        {{
                            getConfigurableLabel.tradeShowHeader
                                ? getConfigurableLabel.tradeShowHeader
                                : "Find a Product or Vendor"
                        }}
                    </h1>
                </div>
            </section>

            <section
                class="section px-1 section pb-8"
                :class="
                    cssVariables['--tradeshow-content-bg-color']
                        ? 'bg-tradeshow-content'
                        : 'bg-white'
                "
            >
                <div class="container mx-18">
                    <div class="columns is-desktop">
                        <div
                            class="column is-size-6 is-center is-two-fifths separator-right flex flex-col justify-center items-center"
                        >
                            <h2
                                class="title mg-font-bold mg-font-title-lg mg-text-title-color"
                            >
                                {{
                                    getConfigurableLabel.featuredColumnHeader
                                        ? getConfigurableLabel.featuredColumnHeader
                                        : "FEATURED PARTNERS"
                                }}
                            </h2>
                            <div
                                class="has-text-white-xxx w-fit-content pt-6"
                                v-for="partner in featuredPartners"
                                :key="partner.name"
                            >
                                <exhibitor-logo-card
                                    class="separator px-4 mb-4 mg-border"
                                    :id="partner.id"
                                    :name="partner.name"
                                    :imgPath="partner.imgPath"
                                ></exhibitor-logo-card>
                            </div>
                        </div>
                        <div class="column column flex flex-col">
                            <h2
                                class="title mg-font-bold mg-font-title-lg mg-text-title-color"
                            >
                                {{
                                    getConfigurableLabel.searchColumnHeader
                                        ? getConfigurableLabel.searchColumnHeader
                                        : "FIND A PRODUCT OR VENDOR"
                                }}
                            </h2>
                            <exhibitor-search-form
                                class="mt-12"
                            ></exhibitor-search-form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import ExhibitorLogoCard from "@/components/shared/ExhibitorLogoCard";
import ExhibitorSearchForm from "@/components/tradeshow/search/ExhibitorSearchForm";
import SponsorsByTiers from "@/components/tradeshow/SponsorsByTiers.vue";

export default {
    data() {
        return {
            featuredPartners: [
                {
                    imgPath:
                        "https://vmp-media.s3.amazonaws.com/demo-images/logos/McKesson.png",
                    name: "McKesson what",
                    id: "100103"
                },
                {
                    imgPath:
                        "https://vmp-media.s3.amazonaws.com/demo-images/logos/abd94be08ae3b04141de20df446f280e.gif",
                    name: "Milkwaukee Tool",
                    id: "100102"
                },
                {
                    imgPath:
                        "https://vmp-media.s3.amazonaws.com/demo-images/logos/knockknock.png",
                    name: "Knock Knock",
                    id: "100101"
                },
                {
                    imgPath:
                        "https://vmp-media.s3.amazonaws.com/demo-images/logos/SAPlogo.png",
                    name: "SAP",
                    id: "100104"
                }
            ]
        };
    },
    computed: {
        getConfigurableLabel: function() {
            return this.$store.getters.tradeshowLabel;
        },

        cssVariables: function() {
            return this.$store.getters.cssVariables;
        },

        getLayoutOptions: function() {
            return this.$store.getters.layoutOptions;
        }
    },
    components: {
        ExhibitorLogoCard,
        ExhibitorSearchForm,
        SponsorsByTiers
    }
};
</script>
